import React from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';

import './Item.css';

const item = (props) => {
    return (
        <li className='our-product-list-item'>
            <div className='our-product-list-item-icon'>
                <FaRegCheckCircle color='green' size='28px' />
            </div>
            <label>{props.children}</label>
        </li>
    );
}

export default item;