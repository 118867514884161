import React from 'react';

import './Webicard.css';
import Layout from './Layout/Layout';
import Home from './Home/Home';
import About from './About/About';
import OurProduct from './OurProduct/OurProduct';
import Pricing from './Pricing/Pricing';
import Examples from './Examples/Examples';
import Advantages from './Advantages/Advantages';
import ContactUs from './ContactUs/ContactUs';
// import Footer from './Footer/Footer';

const webicard = (props) => {
    return (
        <div className="webicard-container">
            <Layout>
                <Home />
                <About />
                <OurProduct />
                <Pricing />
                <Examples />
                <Advantages />
                <ContactUs />
                {/* <Footer /> */}
            </Layout>
        </div>
    );
    
}

export default webicard;

