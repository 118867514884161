import React from 'react';
import { IoIosArrowDropdown } from 'react-icons/io';
import { Link, Element } from 'react-scroll';

import './Home.css';
import IphoneImage from '../../assets/iphone-digital-card.png';

const home = (props) => {
    return (
        <Element name='home'>
            <section className="home-section-container">
                <div className="home-section-main-container">
                    <div className="home-section-titles">
                        <div className="home-section-main-title">כרטיס ביקור דיגיטלי</div>
                        <div className="home-section-sub-title">כרטיס חכם ויוקרתי שישאיר רושם על הלקוחות שלך</div>
                    </div>

                    
                    <div className="home-section-iphone-mockup-container">
                        <img className="home-section-iphone-mockup-image" src={IphoneImage} alt="iphone" />
                    </div>
                    


                    <Link className="home-section-scroll-down-container" to="about" spy={true} smooth={true} offset={6} duration={1000}>
                        <IoIosArrowDropdown className='home-section-scroll-down-arrow' color='black' size='40px' />
                    </Link>

                </div>

                <div className="home-section-big-screen-only-container">
                    <span className="home-section-big-screen-only-title">ווביקארד - האתר שיוביל את העסק שלך</span>
                    <div className="home-section-big-screen-only-content-container">
                        <span>כרטיס הביקור הדיגיטלי של חברת ווביקארד מציע לכם לרכוש את האתר האולטימטיבי עבורכם ועבור העסק שלכם.</span>
                        <span>אנו מציעים שילוב מנצח של מראה יוקרתי יחד עם חווית משתמש לא פחות ממושלמת ללקוחות שלכם.</span>
                    </div>

                </div>
            </section>
        </Element>
    );
}

export default home;