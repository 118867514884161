import React from 'react';
import { Element } from 'react-scroll';

import './Advantages.css';
import Advantage from './Advantage/Advantage';
import ResponsiveImage from '../../assets/responsive.png';
import SocialMediaImage from '../../assets/social-media-emojis.png';
import UserLoginImage from '../../assets/user-login.png';
// import AutoSMSImage from '../../assets/auto-sms.jpg';
import ProgrammerImage from '../../assets/programmer.png';
import UXImage from '../../assets/ux.jpg';
import ShareImage from '../../assets/share.jpg';
import LuxuryDesignImage from '../../assets/luxury-design.png';
// import LuxuryDesignImage from '../../assets/luxury-design.jpg';
import ContactImage from '../../assets/contact.jpg';

const advantages = (props) => {
    return (
        <Element name="advantages">
            <section className='advantages-section-container'>
                <div className='advantages-title-container'>
                    <div className='advantages-title'>מה מיוחד אצלנו?</div>
                    <div className='advantages-title-underline'></div>
                </div>

                <div className='advantages-content-container'>
                    <Advantage name='responsive' image={ResponsiveImage} header='עיצוב מותאם לכל מסך' description='הכרטיס שלך יהיה מותאם לכל סוגי המסכים מקטן ועד גדול - סמארטפון, טאבלט ומחשב.' />
                    <Advantage name='social-media' image={SocialMediaImage} header='חשיפה לכל הרשתות החברתיות במקום אחד' description={`פייסבוק, אינסטגרם, וואטסאפ, ווייז, יוטיוב, טוויטר, מסנג'ר, לינקדאין ועוד...`} />
                    <Advantage name='user-login' image={UserLoginImage} header='שליטה מלאה על תוכן הכרטיס' description={`רוצים לשנות כיתוב? החלפתם מספר טלפון? רוצים לשנות ניסוח הודעה? פיתחנו מערכת ייחודית לנו שבה אתם נכנסים עם משתמש משלכם, ומשנים מה שרוצים מתי שרוצים. למה לשלם כשאפשר בחינם :-)`} />
                    {/* <Advantage name='auto-sms' image={AutoSMSImage} header='נשלח אוטומטית לאחר שיחה גם אם לא נענתה' description={`פיתחנו אפליקציה ייחודית לנו שתעשה את העבודה עבורכם, והיא תשלח את הכרטיס הדיגיטלי שלכם ללקוחות שלכם בסיום השיחה גם אם השיחה לא נענתה. כמובן שהאפליקציה שלנו יודעת לא להטריד את הלקוח, והיא תישלח לאחר תקופת זמן שאתם תחליטו.`} /> */}
                    <Advantage name='programmer' image={ProgrammerImage} header='נבנה על ידי מתכנת' description='הכרטיס נבנה בעזרת הטכנולוגיות הכי חדשות והכי חזקות בשוק. למתכנת יש את הכח לתת את חוויית המשתמש הטובה ביותר, ויכול לתת לכם מגוון גדול יותר של אפשרויות.' />
                    <Advantage name='ux' image={UXImage} header='חווית משתמש' description='עשינו את המיטב כדי לתת ללקוחות שלכם את ההרגשה שהם גולשים באתר שמרגיש כמו אפליקציה מקצועית יחד עם נראות ברמה הגבוהה ביותר.' />
                    <Advantage name='share' image={ShareImage} header='שיתוף מהיר בלחיצת כפתור' description='הלקוחות שלכם יוכלו לשתף את הכרטיס שלכם בלחיצת כפתור פשוטה, מהירה ונוחה.' />
                    {/* <Advantage name='luxury-design' image={LuxuryDesignImage} header='עיצוב יוקרתי' description='בימינו, צריך שהכל יהיה לא פחות ממושלם. גם אם יש לכם אתר אינטרנט, חובה להשקיע בנראות כדי להעביר ללקוח שיש כאן עסק מקצועי. אנחנו נתן לכם את הכרטיס המושלם שישלב נראות וחווית משתמש לא פחות ממושלמת.' /> */}
                    <Advantage name='contact' image={ContactImage} header='התקשרות מהירה והוספה לאנשי הקשר' description={`הלקוחות שלכם יוכלו ליצור אתכם קשר בקלות ובמהירות בטלפון, וואטסאפ, אימייל, הודעת SMS, בפייסבוק מסנג'ר, באינסטגרם ועוד...`} />
                </div>
            </section>
        </Element>
    );
}

export default advantages;
