import React from 'react';

import './List.css';
import Item from './Item/Item';

const list = (props) => {
    return (
        <ul className='our-product-list'>
            <Item>עיצוב יוקרתי ואלגנטי שישאיר רושם מקצועי ללקוחות שלך.</Item>
            <Item>אתם שולטים בתוכן הכרטיס - אנחנו נותנים לכם את הכח לשנות את התוכן מתי שרק תרצו בקלות ובמהירות.</Item>
            <Item>יצירת קשר מהירה, פשוטה ונוחה עם העסק שלך - חיוג, שליחת הודעת SMS, מייל ועוד...</Item>
            <Item>חשיפה לכל הרשתות החברתיות - פייסבוק, אינסטגרם, טוויטר, לינקדאין ועוד...</Item>
            <Item>ניווט לעסק שלך בלחיצת כפתור מהירה ופשוטה.</Item>
            <Item>הסבר אודות העסק שלך.</Item>
            <Item>גלריית תמונות יפהפיה לבחירתך.</Item>
            <Item>שיתוף סרטון יוטיוב של העסק.</Item>
            <Item>שיתוף מהיר וקל בלחיצת כפתור פשוטה במגוון אפשרויות.</Item>
            <Item>חווית משתמש מושלמת לכם וללקוחות שלכם.</Item>
        </ul>
    );
}

export default list;