import React from 'react';
import { FaShekelSign, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import './Package.css';

const Package = props => {
    const { title, titleStyle, price, detailsArray, remarks } = props;

    const detailsArrayJSX = detailsArray.map(detailObj => {
        const { isIncluded, label } = detailObj;
        let icon = <FaCheckCircle color='green' size='16' />;
        if (!isIncluded) {
            icon = <FaTimesCircle color='red' size='16' />;
        }
        return (
            <div key={label} className='pricing-package-detail-container'>
                <div className='pricing-package-detail-icon-container'>
                    {icon}
                </div>
                <label style={detailObj.style}>{detailObj.label}</label>
            </div>
        );
    });

    return (
        <div className='pricing-package-container'>
            <div className='pricing-package-title-container' style={titleStyle}>
                {title}
            </div>

            <div className='pricing-package-price-container'>
                <div>
                    <label className='pricing-package-price-label'>{price}</label>
                    <FaShekelSign color='black' size='12' />
                </div>
                <label className='pricing-package-price-per-month-label'>לחודש</label>
            </div>

            <div className='pricing-package-details-container'>
                {detailsArrayJSX}
            </div>

            <div className='pricing-package-remarks-container' >
                {remarks}
            </div>

            {/* <div className='pricing-package-order-now-button-container'>
                <button className='pricing-package-order-now-button' style={{ background: `${titleStyle.background}`, color: `${titleStyle.color}` }}>להזמנה לחצו כאן</button>
            </div> */}

        </div>
    );
}

export default Package;