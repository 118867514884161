import React from 'react';
import Fade from 'react-reveal/Fade';

import './Advantage.css';

const advantage = (props) => {
    const { name, image, header, description } = props;

    return (
        <Fade bottom>

            <div className='advantage-container'>
                <div className='advantage-image-container'>
                    <img src={image} alt={name} />
                </div>
                <div className='advantage-text-container'>
                    <label className='advantage-text-header'>{header}</label>
                    <p className='advantage-text-description'>{description}</p>
                </div>
            </div>

        </Fade>
    );
}

export default advantage;