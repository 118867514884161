import React from 'react';
import { Element } from 'react-scroll';

import './Pricing.css';
import Package from './Package/Package';

const Pricing = props => {
    const classicPackage = [
        {
            isIncluded: true,
            label: 'כפתורי התקשרות, רשתות חברתיות, ניווט ועוד'
        },
        {
            isIncluded: true,
            label: 'גלריית תמונות'
        },
        {
            isIncluded: true,
            label: 'הטמעת סרטון וידאו (יוטיוב)'
        },
        {
            isIncluded: true,
            label: 'המלצות לקוחות'
        },
        {
            isIncluded: false,
            label: 'עיצוב מותאם אישית'
        },
        {
            isIncluded: false,
            label: 'כפתור מהיר להוספת העסק לאנשי הקשר בטלפון הלקוח'
        },
        {
            isIncluded: false,
            label: 'כפתור וואטסאפ מהיר, קישורים לאפליקציות, מחירון, שעות פעילות ועוד...'
        },
        {
            isIncluded: false,
            label: 'טופס ליצירת קשר'
        },
        {
            isIncluded: false,
            label: `הטמעת קוד (פיקסל) של דף הפייסבוק העסקי שלכם`
        },
        {
            isIncluded: true,
            label: 'כפתורי שיתוף'
        },
        // {
        //     isIncluded: true,
        //     label: 'כניסת משתמש ייחודית לעדכונים ושינויים בכרטיס מכל מקום בכל שעה (בלעדי ללקוחות ווביקארד)',
        //     style: {
        //         fontWeight: 'bold',
        //         color: '#E95F13'
        //     }
        // },
    ];

    const proPackage = [
        {
            isIncluded: true,
            label: 'כפתורי התקשרות, רשתות חברתיות, ניווט ועוד'
        },
        {
            isIncluded: true,
            label: 'גלריית תמונות'
        },
        {
            isIncluded: true,
            label: 'הטמעת סרטון וידאו (יוטיוב)'
        },
        {
            isIncluded: true,
            label: 'המלצות לקוחות'
        },
        {
            isIncluded: false,
            label: 'עיצוב מותאם אישית'
        },
        {
            isIncluded: true,
            label: 'כפתור מהיר להוספת העסק לאנשי הקשר בטלפון הלקוח'
        },
        {
            isIncluded: true,
            label: 'כפתור וואטסאפ מהיר, קישורים לאפליקציות, מחירון, שעות פעילות ועוד...'
        },
        {
            isIncluded: true,
            label: 'טופס ליצירת קשר'
        },
        {
            isIncluded: true,
            label: `הטמעת קוד (פיקסל) של דף הפייסבוק העסקי שלכם`
        },
        {
            isIncluded: true,
            label: 'כפתורי שיתוף'
        },
        // {
        //     isIncluded: true,
        //     label: 'כניסת משתמש ייחודית לעדכונים ושינויים בכרטיס מכל מקום בכל שעה (בלעדי ללקוחות ווביקארד)',
        //     style: {
        //         fontWeight: 'bold',
        //         color: '#830FDF'
        //     }
        // },
    ];

    const premiumPackage = [
        {
            isIncluded: true,
            label: 'כפתורי התקשרות, רשתות חברתיות, ניווט ועוד'
        },
        {
            isIncluded: true,
            label: 'גלריית תמונות'
        },
        {
            isIncluded: true,
            label: 'הטמעת סרטון וידאו (יוטיוב)'
        },
        {
            isIncluded: true,
            label: 'המלצות לקוחות'
        },
        {
            isIncluded: true,
            label: 'עיצוב מותאם אישית'
        },
        {
            isIncluded: true,
            label: 'כפתור מהיר להוספת העסק לאנשי הקשר בטלפון הלקוח'
        },
        {
            isIncluded: true,
            label: 'כפתור וואטסאפ מהיר, קישורים לאפליקציות, מחירון, שעות פעילות ועוד...'
        },
        {
            isIncluded: true,
            label: 'טופס ליצירת קשר'
        },
        {
            isIncluded: true,
            label: `הטמעת קוד (פיקסל) של דף הפייסבוק העסקי שלכם`
        },
        {
            isIncluded: true,
            label: 'כפתורי שיתוף'
        },
        //{
        //     isIncluded: true,
        //     label: 'כניסת משתמש ייחודית לעדכונים ושינויים בכרטיס מכל מקום בכל שעה (בלעדי ללקוחות ווביקארד)',
        //     style: {
        //         fontWeight: 'bold',
        //         color: '#006EC0'
        //     }
        // },
    ];

    const classicTitleStyle = {
        background: `linear-gradient(270deg, rgba(255,152,61,1) 23%, rgba(223,70,0,1) 100%)`,
        color: 'white',
        borderRadius: '20px 20px 0 0',
    };

    const proTitleStyle = {
        background: `linear-gradient(270deg, rgba(180,26,222,1) 28%, rgba(63,0,223,1) 100%)`,
        color: 'white',
        borderRadius: '20px 20px 0 0',
    }

    const premiumTitleStyle = {
        background: `linear-gradient(90deg, rgba(2,0,36,0.7906512946975666) 0%, rgba(1,69,167,1) 45%, rgba(0,212,255,1) 100%)`,
        color: 'white',
        borderRadius: '20px 20px 0 0',
    };

    return (
        <Element name="pricing">
            <section className='pricing-section-container'>
                <div className='pricing-title-container'>
                    <div className='pricing-title'>מחירון</div>
                    <div className='pricing-title-underline'></div>
                </div>

                <div className='pricing-content-container'>
                    <Package
                        title='קלאסי'
                        titleStyle={classicTitleStyle}
                        price='39'
                        detailsArray={classicPackage}
                        remarks={(
                            <React.Fragment>
                                <div>ללא דמי הקמה!</div>
                            </React.Fragment>

                        )}
                    />
                    <Package
                        title='פרו'
                        titleStyle={proTitleStyle}
                        price='49'
                        detailsArray={proPackage}
                        remarks={(
                            <React.Fragment>
                                <div>ללא דמי הקמה!</div>
                            </React.Fragment>

                        )}
                    />
                    <Package
                        title='פרימיום'
                        titleStyle={premiumTitleStyle}
                        price='59'
                        detailsArray={premiumPackage}
                        remarks={(
                            <React.Fragment>
                                <div>דמי הקמה בסך 550 ש"ח</div>
                            </React.Fragment>

                        )}
                    />

                </div>
            </section>

        </Element>

    );
}

export default Pricing;