import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-scroll';
// import { Link as RouterLink } from 'react-router-dom';
// import { FaUser } from 'react-icons/fa';

import './Toolbar.css';
import { AuthContext } from '../../../shared/context/auth-context';
import logoImg from '../../../assets/logo.png';
import NavLink from './NavLink/NavLink';


const Toolbar = () => {
    const auth = useContext(AuthContext);
    const [isScrolledFromTop, setIsScrolledFromTop] = useState(false);

    const onScrollHandler = (event) => {
        if (document.documentElement.scrollTop > 0 || document.body.scrollTop > 0) {
            setIsScrolledFromTop(true);
        }
        else {
            setIsScrolledFromTop(false);
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', onScrollHandler);

        // Cleanup function (like componentWillUnmount)
        return () => {
            document.removeEventListener('scroll', onScrollHandler);
        }
    }, []);

    const toggleMenu = (event) => {
        const nav = document.getElementById('nav');
        nav.classList.toggle('active');
    }


    let navClass = '';
    if (isScrolledFromTop) {
        navClass = 'nav-scrolled'
    }

    let usersSectionNavLink = '/login';
    if(auth.isLoggedIn) {
        usersSectionNavLink = '/users';
    }

    return (
        <header>
            <nav id='nav' className={navClass}>
                <div className='logo'>
                    <Link to='home' spy={true} smooth={true} duration={800}>
                        <img src={logoImg} alt='logo' />
                    </Link>
                </div>

                {/* Users login disabled - 13.10.2021 */}
                {/* <RouterLink to={usersSectionNavLink} className='toolbar-login-button-container'>
                    <button type='button' className='toolbar-login-button'>
                        <span className='toolbar-login-button-label'>כניסת משתמשים</span>
                        <FaUser color='black' size='16px' style={{ marginBottom: '-3px' }} />
                    </button>
                </RouterLink> */}

                <ul>
                    <li><NavLink to='home' onClick={toggleMenu} isScrolledFromTop={isScrolledFromTop}>דף ראשי</NavLink></li>
                    <li><NavLink to='about' offset={6} onClick={toggleMenu} isScrolledFromTop={isScrolledFromTop}>מי אנחנו</NavLink></li>
                    <li><NavLink to='our-product' offset={7} onClick={toggleMenu} isScrolledFromTop={isScrolledFromTop}>המוצר שלנו</NavLink></li>
                    <li><NavLink to='pricing' offset={8} onClick={toggleMenu} isScrolledFromTop={isScrolledFromTop}>מחירון</NavLink></li>
                    <li><NavLink to='examples' offset={12} onClick={toggleMenu} isScrolledFromTop={isScrolledFromTop}>דוגמאות</NavLink></li>
                    <li><NavLink to='advantages' offset={6} onClick={toggleMenu} isScrolledFromTop={isScrolledFromTop}>יתרונות</NavLink></li>
                    <li><NavLink to='contact-us' offset={9} onClick={toggleMenu} isScrolledFromTop={isScrolledFromTop}>צור קשר</NavLink></li>
                </ul>
                <div className='nav-toggle' onClick={toggleMenu}></div>
            </nav>
        </header>
    );

}

export default Toolbar;