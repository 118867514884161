import React from 'react';
import { Element, Link } from 'react-scroll';
import { FaRegArrowAltCircleDown } from 'react-icons/fa';

import './About.css';

const about = (props) => {
    return (
        <Element name="about">
            <section className='about-section-container'>
                <div className='about-title-container'>
                    <div className='about-title'>מי אנחנו?</div>
                    <div className='about-title-underline'></div>
                </div>

                <div className='about-content-container'>
                    <p>שלום!</p>
                    <p>אנחנו שמחים מאוד שהגעתם לכאן :-)</p>
                    <p>אז בואו נכיר,</p>
                    <p>אנחנו ווביקארד, חברה שנוסדה ע"י מהנדס תוכנה המתמחה בפיתוח Web בעל ניסיון של שנים בפיתוח אתרים, ואנחנו כאן כדי לבנות לכם את האתר שיקח את העסק שלכם כמה צעדים קדימה.</p>
                    <p>עם ניסיון של שנים בפיתוח אתרים - תוך שימוש בטכנולוגיות הכי חדשות וחזקות בשוק ומתן שירות ברמה הגבוהה ביותר, אנחנו כאן כדי שלכם יהיה את הכרטיס הדיגיטלי המושלם שיגרום ללקוחות שלכם להגיד "וואו!!!".</p>
                    <p>אנחנו נבנה לכם את האתר המושלם לעסק שלכם המשלב נראות יוקרתית יחד עם חווית משתמש ברמה הגבוהה ביותר. נחשוף את הלקוחות שלכם לכל העמודים שלכם ברשתות החברתיות וניתן להם ליצור איתכם קשר בלחיצת כפתור פשוטה ומהירה - והכל במקום אחד.</p>
                    <p>אצלנו, שירות הוא מעל הכל. אנחנו כאן כדי להקשיב לכם, לענות לכם על כל השאלות ולתת לכם את המיטב. יש לכם רעיון למשהו שלא ראיתם אצלנו? מעולה! שתפו אותנו ונעשה את המקסימום כדי שזה יתממש.</p>
                    <p>אז בואו להתרשם מהמוצר שלנו - אנחנו מבטיחים לכם שגם אתם תתאהבו.</p>
                </div>

                <div className='about-go-to-next-section-container'>
                    <Link className='about-click-here-to-scroll-down-container' to="our-product" offset={6} spy={true} smooth={true} duration={1000}>
                        <span>לחצו כאן להתרשמות מהמוצר שלנו</span>
                    </Link>
                    
                    <Link className='about-scroll-down-arrow-container' to="our-product" offset={6} spy={true} smooth={true} duration={1000}>
                        <FaRegArrowAltCircleDown className='about-scroll-down-arrow' color='#17A3EA' size='30px' />
                    </Link>
                </div>

            </section>
        </Element>
    );
}

export default about;