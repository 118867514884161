import React, { useState } from 'react';
import { Element } from 'react-scroll';

import './Form.css';
import { useHttpClient } from '../../../shared/hooks/http-hook';
import { useForm } from '../../../shared/hooks/form-hook';
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH } from '../../../shared/util/validators';
import Input from './Input/Input';
import LoadingSpinner from '../../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../../shared/components/UIElements/ErrorModal';
import SubmitSuccess from '../../../shared/components/UIElements/CheckSignAnimation';

const Form = props => {
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
    const { isLoading, error, setError, sendRequest, clearError } = useHttpClient();
    const [formState, inputHandler] = useForm(
        {
            name: {
                value: '',
                isValid: false
            },
            phoneNum: {
                value: '',
                isValid: false
            },
            email: {
                value: '',
                isValid: true
            },
            message: {
                value: '',
                isValid: false
            }
        },
        false
    );

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        if (formState.isValid) {
            try {
                await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/official-website/form`,
                    'POST',
                    {
                        'Content-Type': 'application/json'
                    },
                    JSON.stringify({
                        name: formState.inputs.name.value,
                        phoneNum: formState.inputs.phoneNum.value,
                        email: formState.inputs.email.value,
                        message: formState.inputs.message.value
                    }),
                );
                setIsSubmitSuccess(true);
            } catch (err) { }
        } else { // Form is not valid
            setError("הטופס אינו תקין.\n יש למלא את כל שדות החובה - שם, טלפון ותוכן הודעה.");
        }
    }

    return (
        <Element name="contact-us-form" className="form-container">
            <div className='form-title-container'>
                <div className="form-title">השאירו פרטים לחזרת נציג</div>
                <div className="form-title-underline"></div>
                <div>יש למלא את כל שדות החובה בטופס:</div>
            </div>

            {isLoading && <LoadingSpinner asOverlay />}
            {!isLoading && isSubmitSuccess && <SubmitSuccess messageTextColor='white' messages={['הטופס נשלח בהצלחה', 'אנו ניצור איתך קשר בהקדם']} />}

            {!isSubmitSuccess && (
                <div className="form-wrapper">
                    <form onSubmit={onSubmitHandler}>
                        <Input
                            id="name"
                            element="input"
                            type="text"
                            placeholder="שם מלא"
                            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(2)]}
                            errorText="יש להזין את שמך המלא"
                            withValidIcon={false}
                            onInput={inputHandler}
                        />

                        <Input
                            id="phoneNum"
                            element="input"
                            type="tel"
                            placeholder="מספר טלפון"
                            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(9)]}
                            errorText="יש להזין מספר טלפון תקין"
                            withValidIcon={false}
                            onInput={inputHandler}
                        />

                        <Input
                            id="email"
                            element="input"
                            type="email"
                            placeholder={`דוא"ל (אופציונאלי)`}
                            validators={[]}
                            errorText={`יש להזין כתובת דוא"ל תקינה`}
                            withValidIcon={false}
                            onInput={inputHandler}
                            initialValid={true}
                        />

                        <Input
                            id="message"
                            element="textarea"
                            rows={6}
                            placeholder="תוכן ההודעה"
                            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(3)]}
                            errorText="נא להזין את תוכן ההודעה"
                            withValidIcon={false}
                            onInput={inputHandler}
                        />


                        <button type='submit' className="form-submit-button primary-background-color primary-border-color secondary-color">שליחה</button>
                    </form>
                </div>
            )}

            {error && <ErrorModal content={error} onClose={clearError} />}

        </Element>
    );
}

export default Form;