import React from 'react';
import { Element } from 'react-scroll';

import './OurProduct.css';
import TheBoxImg from '../../assets/our-product-preview.png';
import List from './List/List';

const ourProduct = (props) => {
    return (
        <Element name="our-product">
            <section className='our-product-section-container'>
                <div className='our-product-title-container'>
                    <div className='our-product-title'>המוצר שלנו</div>
                    <div className='our-product-title-underline'></div>
                </div>


                <div className='our-product-content-container'>
                    <div className='our-product-iphone-mockup-container'>
                        <img src={TheBoxImg} alt='iphone-mockup' />
                    </div>

                    <div className='our-product-list-container'>
                        <List />
                    </div>
                </div>

            </section>
        </Element>
    );
}

export default ourProduct;