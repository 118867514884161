import React from 'react';
import { FacebookProvider, Page } from 'react-facebook';
import { isMobile, isIOS } from "react-device-detect";

import './FollowUs.css';
import InstagramFollowUsImg from '../../../assets/Instagram-webicard.png';
import FacebookFollowUsImg from '../../../assets/facebook-webicard.png';
import TwitterFollowUsImg from '../../../assets/twitter-webicard.png';


const followUs = (props) => {
    const FACEBOOK_PAGE_ID = '110842710453704';
    const FACEBOOK_PAGE_NAME = 'WebiCard-110842710453704';

    const INSTAGRAM_PAGE_NAME = 'webicard';
    const instagramLink = 'https://www.instagram.com/' + INSTAGRAM_PAGE_NAME;

    const TWITTER_PROFILE_NAME = 'webicard';
    let twitterLink = 'https://twitter.com/' + TWITTER_PROFILE_NAME;

    // Browser links
    let facebookLink = 'https://www.facebook.com/' + FACEBOOK_PAGE_ID; // Browser link.

    // Check if user using mobile or tablet.
    if(isMobile) {
        facebookLink = 'fb://facewebmodal/f?href=https://www.facebook.com/' + FACEBOOK_PAGE_NAME;
        if(isIOS) {
            facebookLink = 'fb://profile/' + FACEBOOK_PAGE_ID;
        }
        // twitterLink = 'twitter://user?screen_name=' + TWITTER_PROFILE_NAME;
    }

    const onClickHandler = (name) => {
        let link = '';
        
        switch(name) {
            case 'facebook':
                link = facebookLink;
                break;

            case 'instagram':
                link = instagramLink;
                break;

            case 'twitter':
                link = twitterLink;
                break;

            default:
                return;
        }
        
        window.location = link;
    }
    
    return (
        <div className='follow-us-container'>
            <div className='follow-us-title-container'>
                <div className='follow-us-title'>עקבו אחרינו</div>
                <div className='follow-us-title-underline'></div>
                <div>לחצו על אחת מהאפשרויות הבאות ועשו לנו לייק / עוקב:</div>
            </div>

            <div className='follow-us-content-container'>
                <img onClick={() => onClickHandler('facebook')} src={FacebookFollowUsImg} alt='facebook-follow-us' />
                <img onClick={() => onClickHandler('instagram')} src={InstagramFollowUsImg} alt='instagram-follow-us' />
                <img onClick={() => onClickHandler('twitter')} src={TwitterFollowUsImg} alt='twitter-follow-us' />
                
                <div className='follow-us-facebook-page-container'>
                    <FacebookProvider className='follow-us-content-facebook-page' appId="https://www.facebook.com/WebiCard-110842710453704">
                        <Page href="https://www.facebook.com/WebiCard-110842710453704" width={300} />
                    </FacebookProvider>
                </div>

            </div>

        </div>
    );
}

export default followUs;