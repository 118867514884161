import React from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';

const ErrorModal = props => {
    const { content, onClose } = props;

    return (
        <Modal
            open={true}
            onClose={onClose}
            size='small'
            style={{ textAlign: 'right' }}
        >
            <Modal.Header>
                <Icon name='remove circle' color='red' style={{ marginLeft: '8px' }} />
                שגיאה
            </Modal.Header>
            
            <Modal.Content style={{ whiteSpace: 'pre-line' }}>
                <p>{content}</p>
            </Modal.Content>

            <Modal.Actions style={{ textAlign: 'left' }}>
                <Button color='green' onClick={onClose} inverted>אישור</Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ErrorModal;