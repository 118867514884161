import React from 'react';

import './Button.css';

const button = (props) => {
    const { icon, backgroundColor, link, label } = props;

    const onClickHandler = () => {
        window.location = link;
    }

    return (
        <div className='contact-ways-button-container'>
            <button onClick={onClickHandler} style={{ background: backgroundColor }}>
                {icon}
            </button>
            <label onClick={onClickHandler}>{label}</label>
        </div>
    );
}

export default button;