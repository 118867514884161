import React from 'react';
import { Element } from 'react-scroll';
import Swiper from 'react-id-swiper';
import { isBrowser } from 'react-device-detect';
import Fade from 'react-reveal/Fade';

import './Examples.css';
import IphoneImage1 from '../../assets/iphone-example-shanishaked.png';
import IphoneImage2 from '../../assets/iphone-example-holtzanddavid.png';
import IphoneImage3 from '../../assets/iphone-example-itaycohen.png';
// import IphoneImage4 from '../../assets/iphone-example-4.png';
// import IphoneImage5 from '../../assets/iphone-example-5.png';

const examples = props => {
    const images = [
        {
            name: 'shani-shaked',
            src: IphoneImage1,
            link: 'https://webicard.co.il/shanishaked'
        },
        {
            name: 'holtz-and-david',
            src: IphoneImage2,
            link: 'https://webicard.co.il/holtzanddavid'
        },
        {
            name: 'itay-cohen',
            src: IphoneImage3,
            link: 'https://webicard.co.il/itaycohen'
        },
        // {
        //     name: 'iphone4',
        //     src: IphoneImage4
        // },
        // {
        //     name: 'iphone5',
        //     src: IphoneImage5
        // },
    ];

    const onImgClickHandler = (link) => {
        if(link && link !== '') {
            window.open(link, '_blank');
        }
    }

    const imagesJSX = images.map((image) => {
        return (
            <div key={image.name} className='examples-slide-container'>
                <img src={image.src} alt={image.name} onClick={() => onImgClickHandler(image.link)} />
                <button onClick={() => onImgClickHandler(image.link)}>לצפייה בכרטיס לחצו כאן</button>
            </div>
        );
    });

    let slidesPerView = 1;

    if (isBrowser) {
        slidesPerView = 3;
    }

    const params = {
        slidesPerView: slidesPerView,
        spaceBetween: 30,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        }
    }

    return (
        <Element name="examples">
            <section className='examples-section-container'>
                <div className='examples-title-container'>
                    <div className='examples-title'>דוגמאות</div>
                    <div className='examples-title-underline'></div>
                </div>

                <Fade right>
                    <div className='examples-swiper-container'>
                        <Swiper {...params}>
                            {imagesJSX}
                        </Swiper>
                    </div>
                </Fade>


            </section>
        </Element>
    );
}

export default examples;