import React from 'react';
import { FaFacebookF, FaWhatsapp, FaSms, FaPhone, FaFacebookMessenger } from "react-icons/fa";
import { IoMdMail } from 'react-icons/io';
import { isMobile, isIOS } from "react-device-detect";

import './ContactWays.css';
import Button from './Button/Button';

const contactWays = (props) => {
    const iconColor = 'white';
    const iconSize = 28;
    const messageSubjectStr = 'התעניינות לגבי כרטיס דיגיטלי לעסק דרך אתר ווביקארד - Webicard';
    const messageBodyStr = 'שלום, הגעתי אליכם דרך אתר ווביקארד. אשמח לשמוע פרטים.';
    const messageBodyStrEncodedText = encodeURI(messageBodyStr);

    const TELEPHONE_NUMBER = '+972505810033';
    const WHATSAPP_NUMBER = '972505810033';
    const EMAIL = 'webicardofficial@gmail.com';

    const FACEBOOK_PAGE_ID = '110842710453704';
    const FACEBOOK_PAGE_NAME = 'webicard';

    // Browser links
    let facebookLink = 'https://www.facebook.com/' + FACEBOOK_PAGE_ID; // Browser link.
    // let twitterLink = 'https://twitter.com/SergioRamos?ref_src=twsrc%5Etfw';


    // Check if user using mobile or tablet.
    if(isMobile) {
        facebookLink = 'fb://facewebmodal/f?href=https://www.facebook.com/' + FACEBOOK_PAGE_NAME;
        if(isIOS) {
            facebookLink = 'fb://profile/' + FACEBOOK_PAGE_ID;
        }
        // twitterLink = 'twitter://user?screen_name=' + TWITTER_PROFILE_NAME;
    }


    const buttons = [
        // {
        //     name: 'phone',
        //     icon: <FaPhone color={iconColor} size={iconSize} />,
        //     backgroundColor: '#25d366',
        //     link: 'tel:' + TELEPHONE_NUMBER,
        //     label: 'חייגו אלינו'
        // },
        // {
        //     name: 'whatsapp',
        //     icon: <FaWhatsapp color={iconColor} size={iconSize} />,
        //     backgroundColor: '#25d366',
        //     link: 'https://wa.me/' + WHATSAPP_NUMBER + '?text=' + messageBodyStrEncodedText,
        //     // link: 'https://api.whatsapp.com/send?phone=' + TELEPHONE_NUMBER + '&text=' + messageBodyStr, 
        //     label: 'התחלת שיחה עם נציג בוואטסאפ'
        // },
        // {
        //     name: 'sms',
        //     icon: <FaSms color={iconColor} size={iconSize} />,
        //     backgroundColor: '#ffbd00',
        //     link: 'sms://?' + TELEPHONE_NUMBER + '&body=' + messageBodyStr,
        //     label: 'שלחו לנו הודעת SMS'
        // },
        {
            name: 'mail',
            icon: <IoMdMail color={iconColor} size={iconSize} />,
            backgroundColor: '#c10841',
            link: 'mailto:' + EMAIL + '?subject=' + messageSubjectStr + '&body=' + messageBodyStr,
            label: EMAIL
        },
        {
            name: 'facebook',
            icon: <FaFacebookF color={iconColor} size={iconSize} />,
            backgroundColor: '#3b5998',
            link: facebookLink,
            label: 'כתבו לנו הודעה דרך דף הפייסבוק שלנו'
        },
        {
            name: 'messenger',
            icon: <FaFacebookMessenger color={iconColor} size={iconSize} />,
            backgroundColor: 'linear-gradient(#00C6FF, #0078FF)',
            link: 'https://m.me/' + FACEBOOK_PAGE_ID,
            label: `כתבו לנו הודעה במסנג'ר`
        },
    ];

    const buttonsJSX = buttons.map((button) => {
        return (
            <Button 
                key={button.name} 
                name={button.name} 
                icon={button.icon}
                backgroundColor={button.backgroundColor}
                link={button.link}
                label={button.label} />
        );
    });

    return (
        <div className='contact-ways-container'>
            <div className='contact-ways-title-container'>
                <div className='contact-ways-title'>צרו קשר</div>
                <div className='contact-ways-title-underline'></div>
                <div>לחצו על אחת מהאפשרויות הבאות:</div>
            </div>

            <div className='contact-ways-content-container'>
                {buttonsJSX}
            </div>
        </div>
    );
}

export default contactWays;