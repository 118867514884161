import React from 'react';

import './CheckSignAnimation.css';

const CheckSignAnimation = (props) => {
    const { messageTextColor, messages } = props;

    let color = 'black';
    if (messageTextColor) {
        color = messageTextColor;
    }

    let messagesJSX = messages.map((message, index) => {
        return (
            <label key={index}>{message}</label>
        );
    });

    return (
        <div className='checked-sign-animation-container'>
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
            <div className='checked-sign-animation-text-container' style={{ color: color }}>
                {messagesJSX}
            </div>
        </div>
    );
}

export default CheckSignAnimation;