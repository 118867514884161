import { useState, useCallback, useEffect } from 'react';
import { getCookieValueByName } from '../util/util';

let logoutCheck;

export const useAuth = () => {
  const [token, setToken] = useState(false);

  const logout = useCallback(() => {
    setToken(null);
    clearInterval(logoutCheck);
  }, []);

  const login = useCallback((token) => {
    setToken(token);

    const oneMinute = 1000 * 60;
    
    logoutCheck = setInterval(() => {
      const myCookie = getCookieValueByName('wc_u');
      if (!myCookie) {
        logout();
      }
    }, oneMinute);
  }, [logout]);

  useEffect(() => {
    const token = getCookieValueByName('wc_u');
    if (token) {
      login(token);
    }
  }, [login]);

  return { token, login, logout };
};
