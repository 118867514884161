import React from 'react';
import { Link } from 'react-scroll';
import { isBrowser } from 'react-device-detect';

import './NavLink.css';

const navLink = (props) => {
    const { to, onClick, children, isScrolledFromTop, offset } = props;
    
    let myStyle = null;

    if(isBrowser && !isScrolledFromTop) {
        myStyle = {
            borderLeft: 'none',
            borderRight: 'none',
        };
    }

    let finalOffset = null;
    if(offset) {
        finalOffset = offset;
    }
    
    return (
        <React.Fragment>
            <Link 
                activeClass='navlink-active'
                to={to}
                spy={true}
                smooth={true}
                offset={finalOffset}
                duration={1000}
                onClick={onClick}
                style={myStyle}>
                    {children}
            </Link>
        </React.Fragment>
    );
}

export default navLink;