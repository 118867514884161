import React from 'react';
import { Element } from 'react-scroll';

import './ContactUs.css';
import FollowUs from './FollowUs/FollowUs';
import Form from './Form/Form';
import ContactWays from './ContactWays/ContactWays';

const contactUs = (props) => {
    return (
        <Element name="contact-us">
            <section className='contact-us-section-container'>
                {/* <div className='contact-us-title-container'>
                    <div className='contact-us-title'>צרו קשר</div>
                    <div className='contact-us-title-underline'></div>
                </div> */}

                <div className='contact-us-content-container'>
                   <ContactWays />
                   <Form />
                   <FollowUs />
                </div>

            </section>
        </Element>
    );
}

export default contactUs;