import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Webicard from './Webicard/Webicard';
import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';

const UsersSection = React.lazy(() => import('./UsersSection/UsersSection'));
const Login = React.lazy(() => import('./Login/Login'));
const Purchase = React.lazy(() => import('./Purchase/Purchase'));
const ApplicationForm = React.lazy(() => import('./ApplicationForm/ApplicationForm'));

function App() {
  const { token, login, logout } = useAuth();

  let routes;

  if (token) {
    routes = (
      <Switch>
        <Route path='/users' component={UsersSection} />
        <Route path='/purchase' component={Purchase} exact />
        <Route path='/' component={Webicard} />
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        {/* Users login disabled - 13.10.2021 */}
        {/* <Route path='/login' component={Login} exact /> */}
        <Route path='/purchase' component={Purchase} exact />
        <Route path='/application-form' component={ApplicationForm} exact />
        <Route path='/' component={Webicard} />
        <Redirect to="/" />
      </Switch>
    );
  }

  const loadingJSX = (
    <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <LoadingSpinner asOverlay />
    </div>
  );

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        login: login,
        logout: logout
      }}
    >
      <BrowserRouter>
        <Suspense fallback={loadingJSX}>
          {routes}
        </Suspense>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
